import { TablePaginationConfig } from "antd";
import fetch from "../auth/FetchInterceptor";
import { ICompany, ICreateCompanyService } from "../interface/company";

export const fetchListCompanyService = (params?: TablePaginationConfig, filters?:string) => {
  return fetch.get("/companies/companylist", {
    params: { page: params?.current, limit: params?.pageSize, filters},
  });
};

export const fetchCreateCompanySiteService = (
  params: ICreateCompanyService
) => {
  return fetch.post("/user-company-site", params);
};
export const fetchDeleteCompanySiteService = (companyId: number = 0) => {
  return fetch.delete(`/user-company-site/companyid/${companyId}`);
};
export const fetchListCompanySiteService = (userId: number = 0) => {
  return fetch.get(`/user-company-site/userid/${userId}`);
};
export const fetchCreateCompany = (params: ICompany) => {
  return fetch.post(`/companies`, params);
};
export const fetchCompanyById = (companyId: number = 0) => {
  return fetch.get(`/companies/${companyId}`);
};
export const fetchUpdateCompanyById = (
  companyId: number = 0,
  params: ICompany
) => {
  return fetch.put(`/companies/${companyId}`, params);
};
export const fetchDeleteCompanyById = (userId: number = 0) => {
  return fetch.delete(`/companies/${userId}`);
};
