import fetch from "../auth/FetchInterceptor";
import { IUser } from "../interface/user";

export const fetchUserProfile = () => {
  return fetch.post("/users/profile");
};

export const fetchListUsers = (params: { filter?: string }) => {
  return fetch.get("/users", { params });
};

export const createUser = (params: IUser) => {
  return fetch.post("/users", params);
};

export const fetchUserById = (userId: number = 0) => {
  return fetch.get(`/users/${userId}`);
};

export const fetchUpdateUserById = (userId: number = 0, params: IUser) => {
  return fetch.put(`/users/${userId}`, params);
};
export const fetchDeleteUserById = (userId: number = 0) => {
  return fetch.delete(`/users/${userId}`);
};
