import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from "../../components/shared-components/Loading";
import { APP_PREFIX_PATH } from "../../configs/AppConfig";
import { roleValue } from "../../constants/Role";

export const AppViews = () => {
  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        <Route
          path={`${APP_PREFIX_PATH}/landing`}
          component={lazy(() => import(`./landing`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/compliance`}
          component={lazy(() => import(`./compliance`))}
        />

        <Route
          path={`${APP_PREFIX_PATH}/managements`}
          component={lazy(() => import(`./managements`))}
        />

        <Route
          path={`${APP_PREFIX_PATH}/dashboards`}
          component={lazy(() => import(`./dashboards`))}
        />

        <Redirect
          from={`${APP_PREFIX_PATH}`}
          to={localStorage.getItem('role')== roleValue.Admin?`${APP_PREFIX_PATH}/dashboards`:`${APP_PREFIX_PATH}/landing`}
        />
      </Switch>
    </Suspense>
  );
};

export default React.memo(AppViews);
