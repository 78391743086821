import { USER_GET_LIST_SUCCESS, USER_PROFILE_SUCCESS } from "../constants/User";

const initState = {
  userProfile: {},
  users: { data: [], pages: 0 },
};

const users = (state = initState, action: any) => {
  switch (action.type) {
    case USER_PROFILE_SUCCESS:
      return { ...state, userProfile: action.payload };
    case USER_GET_LIST_SUCCESS:
      return { ...state, users: { ...state.users, ...action.payload } };
    default:
      return state;
  }
};

export default users;
