import { TablePaginationConfig } from "antd";
import fetch from "../auth/FetchInterceptor";
import { ISite, ISiteCompanyIdService } from "../interface/site";
export const fetchListSiteService = (params?: TablePaginationConfig, filters?:string) => {
  return fetch.post("/site/get-all-site", { page: params?.current, limit: params?.pageSize, filters});
};

export const fetchSiteByCompanyId = (params: ISiteCompanyIdService) => {
  return fetch.post("/site/get-site-by-company", params);
};
export const fetchCreateSite = (params: ISite) => {
  return fetch.post(`/site`, params);
};
export const fetchSiteById = (siteId: number = 0) => {
  return fetch.get(`/site/${siteId}`);
};
export const fetchSuspendSite = (siteId: number = 0) => {
  return fetch.post(`/site/suspend/${siteId}`);
};
export const fetchUpdateSite = (siteId: number = 0, params: ISite) => {
  return fetch.put(`/site/${siteId}`, params);
};
export const fetchDeleteSiteById = (siteId: number = 0) => {
  return fetch.delete(`/site/${siteId}`);
};
export const fetchListSiteByUserIdService = (userId: number,data: any) => {
  return fetch.post(`/site/userid/${userId}`,data);
};
