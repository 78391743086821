import { IDataListResponse } from "../../interface/response";
import { IUser } from "../../interface/user";
import {
  USER_GET_LIST,
  USER_GET_LIST_SUCCESS,
  USER_PROFILE,
  USER_PROFILE_SUCCESS,
} from "../constants/User";

export const fetchUserProfile = () => {
  return { type: USER_PROFILE };
};

export const fetchUserProfileSuccess = (payload: IUser) => {
  return { type: USER_PROFILE_SUCCESS, payload };
};

export const fetchListUsersAction = (params: { filter?: string }) => {
  return { type: USER_GET_LIST, params };
};

export const fetchListUsersSuccess = (payload: IDataListResponse<IUser>) => {
  return { type: USER_GET_LIST_SUCCESS, payload };
};
