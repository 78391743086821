import { all, call, put, takeEvery, fork } from "redux-saga/effects";
import { COMPANY_GET_LIST } from "../constants/Company";

import { fetchListCompanyService } from "../../services/CompanyService";
import { fetchListCompanySuccess } from "../actions/Company";

export function* getListCompany() {
  yield takeEvery(COMPANY_GET_LIST, function* ({ params }: any): any {
    try {
      const company = yield call(fetchListCompanyService, params);
      const { data = [] } = company;
      const payload = { data };
      yield put(fetchListCompanySuccess(payload));
    } catch (err: any) {}
  });
}

export default function* rootSaga() {
  yield all([fork(getListCompany)]);
}
