import { combineReducers } from "redux";
import Auth from "./Auth";
import Company from "./Company";
import Site from "./Site";
import Theme from "./Theme";
import Users from "./Users";
const reducers = combineReducers({
  theme: Theme,
  auth: Auth,
  users: Users,
  site: Site,
  company: Company,
});

export type RootStateProps = ReturnType<typeof reducers>;

export default reducers;
