import { all, call, put, takeEvery, fork } from "redux-saga/effects";
import { SITE_GET_LIST, SITE_GET_LIST_BY_COMPANY_ID } from "../constants/Site";

import {
  fetchListSiteService,
  fetchSiteByCompanyId,
} from "../../services/SiteService";
import {
  fetchListSiteByCompanyIdSuccess,
  fetchListSiteSuccess,
} from "../actions/Site";
import { ISiteCompanyIdService } from "../../interface/site";

export function* getListSite() {
  yield takeEvery(SITE_GET_LIST, function* ({ params }: any): any {
    try {
      const site = yield call(fetchListSiteService, params);
      const { data = [] } = site;
      const payload = { data };
      yield put(fetchListSiteSuccess(payload));
    } catch (err: any) {}
  });
}

export function* getListSiteByCompanyId() {
  yield takeEvery(
    SITE_GET_LIST_BY_COMPANY_ID,
    function* ({ params }: { params: ISiteCompanyIdService } | any): any {
      try {
        const site = yield call(fetchSiteByCompanyId, params);
        const { data = [], pages = 0, totalItems = 0 } = site;
        const payload = { data, pages, totalItems };
        yield put(fetchListSiteByCompanyIdSuccess(payload));
      } catch (err: any) {}
    }
  );
}

export default function* rootSaga() {
  yield all([fork(getListSite), fork(getListSiteByCompanyId)]);
}
