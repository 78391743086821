import { ILogin } from "../../interface/auth";
import {
  SIGNIN,
  AUTHENTICATED,
  SIGNOUT,
  SIGNOUT_SUCCESS,
  SHOW_AUTH_MESSAGE,
  HIDE_AUTH_MESSAGE,
  SIGNUP,
  SIGNUP_SUCCESS,
  SHOW_LOADING,
  AUTH_LOGIN,
} from "../constants/Auth";

export const signIn = (user: any) => {
  return {
    type: SIGNIN,
    payload: user,
  };
};

export const authenticated = (token: string | any) => {
  return { type: AUTHENTICATED, token };
};

export const signOut = () => {
  return { type: SIGNOUT };
};

export const signOutSuccess = () => {
  return { type: SIGNOUT_SUCCESS };
};

export const signUp = (user: Object | any) => {
  return { type: SIGNUP, payload: user };
};

export const signUpSuccess = (token: string | any) => {
  return { type: SIGNUP_SUCCESS, token };
};

export const showAuthMessage = (message: string) => {
  return { type: SHOW_AUTH_MESSAGE, message };
};

export const hideAuthMessage = () => {
  return { type: HIDE_AUTH_MESSAGE };
};

export const showLoading = () => {
  return { type: SHOW_LOADING };
};

export const login = (params: ILogin) => {
  return { type: AUTH_LOGIN, payload: params };
};
