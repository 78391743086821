import { IDataListResponse } from "../../interface/response";
import {
  COMPANY_GET_LIST,
  COMPANY_GET_LIST_SUCCESS,
} from "../constants/Company";

export const fetchListCompanyAction = (params?: { filters?: string }) => {
  return { type: COMPANY_GET_LIST, params };
};

export const fetchListCompanySuccess = (payload: IDataListResponse<any>) => {
  return { type: COMPANY_GET_LIST_SUCCESS, payload };
};
