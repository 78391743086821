import React, { CSSProperties, FC } from "react";
import PropTypes from "prop-types";

interface IFlexProps {
  className?: string;
  alignItems?: string;
  flexDirection?: string;
  justifyContent?: string;
  mobileFlex?: boolean;
  style?: CSSProperties;
}

const Flex: FC<IFlexProps> = (props) => {
  const {
    children,
    className,
    alignItems,
    justifyContent,
    mobileFlex,
    flexDirection,
    style,
  } = props;
  const getFlexResponsive = () => (mobileFlex ? "d-flex" : "d-md-flex");
  return (
    <div
      style={style}
      className={`${getFlexResponsive()} ${className} ${
        flexDirection ? "flex-" + flexDirection : ""
      } ${alignItems ? "align-items-" + alignItems : ""} ${
        justifyContent ? "justify-content-" + justifyContent : ""
      }`}
    >
      {children}
    </div>
  );
};

Flex.propTypes = {
  className: PropTypes.string,
  alignItems: PropTypes.string,
  flexDirection: PropTypes.string,
  justifyContent: PropTypes.string,
  mobileFlex: PropTypes.bool,
  style: PropTypes.object,
};

Flex.defaultProps = {
  mobileFlex: true,
  flexDirection: "row",
  className: "",
};

export default Flex;
