import { DashboardOutlined } from "@ant-design/icons";
import { APP_PREFIX_PATH } from "../configs/AppConfig";
import { roleValue } from "../constants/Role";

export interface INavTree {
  key?: string;
  path?: string;
  title?: string;
  icon?: any;
  breadcrumb?: boolean;
  permission?: string[];
  submenu?: INavTree[];
}

const dashboardNavTree = [
  {
    key: "landing",
    path: `${APP_PREFIX_PATH}/dashboards`,
    title: "sidenav.dashboard",
    icon: DashboardOutlined,
    breadcrumb: false,
    permission: [roleValue.Admin],
    submenu: [
      {
        key: "dashboard-navbar",
        path: `${APP_PREFIX_PATH}/dashboards`,
        title: "sidenav.dashboard",
        icon: DashboardOutlined,
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
];

const landingNavTree = [
  {
    key: "landing",
    path: `${APP_PREFIX_PATH}/landing`,
    title: "sidenav.landing",
    icon: DashboardOutlined,
    breadcrumb: false,
    permission: [
      roleValue.AreaManager,
      roleValue.SiteManager,
      roleValue.seniorContact,
    ],
    submenu: [
      {
        key: "landing-home",
        path: `${APP_PREFIX_PATH}/landing`,
        title: "sidenav.landing",
        icon: DashboardOutlined,
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
];

const managementNavTree = [
  {
    key: "management",
    path: `${APP_PREFIX_PATH}/managements`,
    title: "sidenav.management",
    icon: DashboardOutlined,
    breadcrumb: false,
    permission: [roleValue.Admin],
    submenu: [
      {
        key: "management-users",
        path: `${APP_PREFIX_PATH}/managements/users`,
        title: "sidenav.management.users",
        icon: DashboardOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "management-companies",
        path: `${APP_PREFIX_PATH}/managements/companies`,
        title: "sidenav.management.companies",
        icon: DashboardOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "management-compliance",
        path: `${APP_PREFIX_PATH}/managements/compliance`,
        title: "sidenav.management.compliance",
        icon: DashboardOutlined,
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
];

const navigationConfig = [
  ...dashboardNavTree,
  ...landingNavTree,
  ...managementNavTree,
];

export default navigationConfig;
