import { COMPANY_GET_LIST_SUCCESS } from "../constants/Company";

const initState = {
  companies: { data: [], pages: 0 },
};

const company = (state = initState, action: any) => {
  switch (action.type) {
    case COMPANY_GET_LIST_SUCCESS:
      return { ...state, companies: { ...state.companies, ...action.payload } };
    default:
      return state;
  }
};

export default company;
