import fetch from "../auth/FetchInterceptor";
import { ILogin, IResetPassord, ISendForgotEmail, ISignUp, IVerifyCode } from "../interface/auth";

export const login = (params: ILogin) => {
  return fetch.post("/auth/login", params);
};
export const sendForgotEmail = (params: ISendForgotEmail) => {
  return fetch.post("/auth/send-mail-forgot", params);
};
export const verifyCode = (params: IVerifyCode) => {
  return fetch.post("/auth/verifyCode", params);
};
export const resetPassword = (params: IResetPassord) => {
  return fetch.post("/auth/reset-password", params);
};

export const signUp = (params: ISignUp) => {
  return fetch.post("/auth/signup", params);
};
