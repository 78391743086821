import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import { AUTH_LOGIN, AUTH_TOKEN, SIGNOUT } from "../constants/Auth";
import {
  showAuthMessage,
  authenticated,
  signOutSuccess,
} from "../actions/Auth";

import FirebaseService from "../../services/FirebaseService";
import { login as LoginService } from "../../services/AuthService";
import { ILogin } from "../../interface/auth";
import { ActionScreen } from "../../interface/actions";

export function* login() {
  yield takeEvery(
    AUTH_LOGIN,
    function* ({ payload }: ActionScreen<ILogin>): any {
      try {
        const user = yield call(LoginService, payload);
        
        if (user?.tokenData) {
          localStorage.setItem(AUTH_TOKEN, user?.tokenData?.token);
          localStorage.setItem('role', user?.data?.role);
          localStorage.setItem('userId', user?.data?.id);
          yield put(authenticated(user?.tokenData?.token));
        }
      } catch (err: any) {
        yield put(showAuthMessage(err.response?.data?.message || err?.message));
      }
    }
  );
}

// export function* register() {
//   yield takeEvery(SIGNIN, function* ({ payload }: ActionScreen<ILogin>): any {
//     const { email, password } = payload;
//     try {
//       const user = yield call(
//         FirebaseService.signInEmailRequest,
//         email,
//         password
//       );
//       if (user.message) {
//         yield put(showAuthMessage(user.message));
//       } else {
//         localStorage.setItem(AUTH_TOKEN, user.user.uid);
//         yield put(authenticated(user.user.uid));
//       }
//     } catch (err: any) {
//       yield put(showAuthMessage(err));
//     }
//   });
// }
export function* signOut() {
  yield takeEvery(SIGNOUT, function* (): any {
    try {
      const signOutUser = yield call(FirebaseService.signOutRequest);
      if (signOutUser === undefined) {
        localStorage.removeItem(AUTH_TOKEN);
        localStorage.removeItem('logoLink');
        yield put(signOutSuccess());
      } else {
        yield put(showAuthMessage(signOutUser.message));
      }
    } catch (err: any) {
      yield put(showAuthMessage(err));
    }
  });
}

export default function* rootSaga() {
  yield all([fork(login), fork(signOut)]);
}
