/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import AppLayout from "../layouts/app-layout";
import AuthLayout from "../layouts/auth-layout";
import AppLocale from "../lang";
import { IntlProvider } from "react-intl";
import { ConfigProvider } from "antd";
import { API_BASE_URL, APP_PREFIX_PATH, AUTH_PREFIX_PATH } from "../configs/AppConfig";
import useBodyClass from "../hooks/useBodyClass";
import { fetchUserProfile } from "../redux/actions/User";
import { fetchListCompanyService } from "../services/CompanyService";
import { roleValue } from "../constants/Role";

function RouteInterceptor({ children, isAuthenticated, ...rest }: any): any {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: AUTH_PREFIX_PATH,
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

export const Views = (props: any) => {
  const { token, location, direction, userProfile } = props;
  const locale: "en" | "zh" | "fr" | "ja" = props.locale || "";
  const currentAppLocale = AppLocale[locale];
  useBodyClass(`dir-${direction}`);

  useEffect(() => {
    if (token){
      userProfile?.();
    } 
    }, [token]);

  return (
    <IntlProvider
      locale={currentAppLocale.locale}
      messages={currentAppLocale.messages}
    >
      <ConfigProvider locale={currentAppLocale.antd} direction={direction}>
        <Switch>
          <Route exact path="/">
            <Redirect to={APP_PREFIX_PATH} />
          </Route>
          <Route path={AUTH_PREFIX_PATH}>
            <AuthLayout />
          </Route>
          <RouteInterceptor path={APP_PREFIX_PATH} isAuthenticated={token}>
            <AppLayout direction={direction} location={location} />
          </RouteInterceptor>
        </Switch>
      </ConfigProvider>
    </IntlProvider>
  );
};

const mapStateToProps = ({ theme, auth }: any) => {
  const { locale, direction } = theme;
  const { token } = auth;
  return { locale, direction, token };
};

const mapDispatchToProps = {
  userProfile: fetchUserProfile,
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Views));
