import { IDataListResponse } from "../../interface/response";
import { ISite, ISiteCompanyIdService } from "../../interface/site";
import {
  SITE_GET_LIST,
  SITE_GET_LIST_SUCCESS,
  SITE_GET_LIST_BY_COMPANY_ID,
  SITE_GET_LIST_BY_COMPANY_ID_SUCCESS,
} from "../constants/Site";

export const fetchListSiteAction = (params?: { filters?: string }) => {
  return { type: SITE_GET_LIST, params };
};

export const fetchListSiteSuccess = (payload: IDataListResponse<any>) => {
  return { type: SITE_GET_LIST_SUCCESS, payload };
};

export const fetchListSiteByCompanyId = (params: ISiteCompanyIdService) => {
  return { type: SITE_GET_LIST_BY_COMPANY_ID, params };
};

export const fetchListSiteByCompanyIdSuccess = (
  payload: IDataListResponse<ISite[]>
) => {
  return { type: SITE_GET_LIST_BY_COMPANY_ID_SUCCESS, payload };
};
