import {
  SITE_GET_LIST_BY_COMPANY_ID,
  SITE_GET_LIST_BY_COMPANY_ID_SUCCESS,
  SITE_GET_LIST_SUCCESS,
} from "../constants/Site";

const initState = {
  sites: { data: [], pages: 0 },
  siteByCompanyId: { data: [], pages: 0 },
};

const site = (state = initState, action: any) => {
  switch (action.type) {
    case SITE_GET_LIST_SUCCESS:
      return { ...state, sites: { ...state.sites, ...action.payload } };

    case SITE_GET_LIST_BY_COMPANY_ID:
      return {
        ...state,
        siteByCompanyId: { ...state.siteByCompanyId, data: [], pages: 0 },
      };

    case SITE_GET_LIST_BY_COMPANY_ID_SUCCESS:
      return {
        ...state,
        siteByCompanyId: { ...state.siteByCompanyId, ...action.payload },
      };

    default:
      return state;
  }
};

export default site;
