export enum roleValue {
  Admin = "Admin",
  SiteManager = "site-manager",
  AreaManager = "area-manager",
  seniorContact = "senior-contact",
}

export const UserRoleConstant = [
  { label: "Administrator", value: roleValue.Admin },
  { label: "Site Manager", value: roleValue.SiteManager },
  { label: "Area Manager", value: roleValue.AreaManager },
  { label: "Senior Contact", value: roleValue.seniorContact },
];
