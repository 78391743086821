import { all, call, put, takeEvery, fork } from "redux-saga/effects";
import { USER_GET_LIST, USER_PROFILE } from "../constants/User";

import { fetchUserProfile, fetchListUsers } from "../../services/UserService";
import {
  fetchUserProfileSuccess,
  fetchListUsersSuccess,
} from "../actions/User";

export function* userProfile() {
  yield takeEvery(USER_PROFILE, function* (): any {
    try {
      const user = yield call(fetchUserProfile);
      yield put(fetchUserProfileSuccess(user?.data));
    } catch (err: any) {}
  });
}

export function* getListUsers() {
  yield takeEvery(USER_GET_LIST, function* ({ params }: any): any {
    try {
      const users = yield call(fetchListUsers, params);
      const { data = [], pages } = users;
      const payload = { data, pages };
      yield put(fetchListUsersSuccess(payload));
    } catch (err: any) {}
  });
}

export default function* rootSaga() {
  yield all([fork(userProfile), fork(getListUsers)]);
}
